<template>
<div class="tgju-widgets-row">
    <div :class="'row tgju-widgets-row mr-1 mb-3 profile-post-widgets profile-mobile-view  '+ market_tab +''">
        <v-select v-model="discussion_active" @input="componentKey += 1,setMarketTab(discussion_active)" class="profile-mobile-view-select" :items="discussions" item-text="value"  item-value="text" label="انتخاب بازار" outlined></v-select>
        <TgjuPost message="شما در این کانال تاکنون دیدگاهی به ثبت نرسانده‌اید." type="widget" auth="hide" :username="this.$helpers.userInfo().username" sendbox="enabled" :subject="discussion_active" col="w100-w" :key="componentKey"></TgjuPost>
    </div>
</div>
</template>


<script>
// این فایل ویو پروفایل خوده کاربر هستش که کاپونتت هایی داخلی استفاده میشوند
import TgjuPost from '@/components/Mobile/TgjuPosts/Post.vue'

export default {
    name: 'ProfileView',
    components: {
        TgjuPost,
    },
    data: function () {
        return {
            market_tab: 'tgju-discussion-18',
            componentKey: 0,
            discussion_active:'tgju-discussion-18',
            discussions: [
                { text: 'tgju-discussion-18', value: 'بازار طلا' },
                { text: 'tgju-discussion-19', value: 'بازار سکه' },
                { text: 'tgju-discussion-20', value: 'بازار ارز' },
                { text: 'tgju-discussion-global-2', value: 'بازار ارزهای دیجیتال' },
                { text: 'tgju-discussion-28', value: 'بازار بورس' },
                { text: 'tgju-discussion-36', value: 'بازار‌های کالایی' },
                { text: 'tgju-discussion-global-1', value: 'بازار‌های سهام' },
                { text: 'tgju-discussion-37', value: 'بازار نفت و انرژی' },
                { text: 'tgju-discussion-global-3', value: 'بازار مبادلات ارزی' },
                { text: 'tgju-discussion-35', value: 'بازار فلزات' },
            ]         
        }
    },
    methods: {
        // این متد برای هندل کردن اکشن تغییر دسته بندی نظرات استفاده میشود
        onChange(event) {
            this.discussion = event.target.value;
            this.componentKey += 1
        },
        // این متد برای تغییر و هایلایت کردند بازار انتخاب شده مورد استفاده قرار میگیرد
        setMarketTab(elm) {
            this.market_tab = elm;
        },
    },
}
</script>
